.container{
    padding-top: 100px;
    padding-bottom: 100px;
    font-family: Arial, Helvetica, sans-serif;
}

.container > h1 {
    text-align: center;
    margin: 0;
    font-size: 40px;
}

.container > p {
    text-align: center;
    margin: 0;
    font-size: 20px;
    letter-spacing: 5px;
    margin-top: 10px;
    color: grey;
}

.container > div:nth-child(3) {
   background-color: #110A40;
    width: 50px;
    height: 6px;
    margin: auto;
    margin-top: 30px;
}

.servicesDetails{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    margin-top: 50px;
    position: relative;
}

.servicesDetails > div {
    width: 45%;
}

.servicesDetails > img {
    position: absolute;
    height: 130%;
}

.card{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}


.card2{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.servicesDetails > div:nth-child(2) > div > div {
    margin-right: 40px;
    text-align: right;
}

.servicesDetails > div:last-child > div > div {
    margin-left: 40px;
}

.card > div > h3,  .card2> div > h3{
    margin: 0;
}

.card > div > p, .card2 > div > p {
    margin: 0;
    color: grey;
}

.card > img, .card2 > img {
    width: 60px;
    height: 60px;
}

@media screen and (max-width: 950px){

    .container {
        padding: 20px;
        padding-top: 50px;
        overflow: hidden;
        padding-bottom: 50px;
    }

    .servicesDetails {
        flex-direction: column;
    }

    .servicesDetails > div {
        width: 100%;
        display: block;
    }

    .card {
        flex-direction: column-reverse;
        border: 2px solid #F1F1F1;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .card2 {
        flex-direction: column;
        border: 1px solid #cbcbcb;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
    }
    

    .card > div, .card2 > div {
        text-align: center !important;
        width: 100%;
        box-sizing: border-box;
        margin: 0 !important;
    }

    .card > img, .card2 > img {
        margin-bottom: 30px;
    }

    .servicesDetails > img {
        display: none;
    }
}