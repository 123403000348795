.container {
    padding: 50px;
    padding-bottom: 0;
    background-color: #16151A;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    overflow: hidden;
}

.container > h1{
    z-index: 10;
    position: relative;
    margin-left: 100px;
    margin-bottom: 100px;
}

.rights {
    z-index: 10;
    position: relative;
    text-align: center;
    color: silver;
    font-style: italic;
}

.mail{
    width: 30px;
    height: auto;
    margin: auto;
    display: block;
    z-index: 10;
    position: relative;
    background-color: #B7B7B7;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
}



.container > img:first-child {
    position: absolute;
    right: 0;
}

.container > img:nth-child(2){
    width: 350px;
    bottom: -80px;
    z-index: 0;
    position: absolute;
}

.container > img:first-child {
    width: 400px;
}


@media screen and (max-width: 950px){

    .container > img:first-child{
        display: none;
        padding: 0;
    }

    .container > h1 {
        text-align: center;
        width: 100%;
        margin: 0;
        margin-bottom: 60px;
        box-sizing: border-box;
    }

}