.container{
    padding-top: 100px;
    padding-bottom: 100px;
    font-family: Arial, Helvetica, sans-serif;
}

.container > h1 {
    text-align: center;
    margin: 0;
    font-size: 40px;
}

.container > p {
    text-align: center;
    margin: 0;
    font-size: 20px;
    letter-spacing: 5px;
    margin-top: 10px;
    color: grey;
}

.container > div:nth-child(3) {
    background-color: #110A40;
     width: 50px;
     height: 6px;
     margin: auto;
     margin-top: 30px;
}

.content{
    width: 90%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.content > img {
    width: 200px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 80px;
    margin-right: 80px;
}


.content > div:first-child{
    text-align: right;
}

.content > div > p {
    color: grey;
}

.content > div > h3 {
    font-size: 20px;
}

.content > div > div > a {
    text-decoration: none;
    background-color: #110A40;
    color: white;
    padding: 7px;
    border-radius: 5px;
    margin-top: 10px;
    display: block;
    width: max-content;
}


.content > div:first-child > div {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 950px){

    .container {
        padding: 20px;
        padding-top: 50px;
        overflow: hidden;
        padding-bottom: 50px;
    }

    .servicesDetails {
        flex-direction: column;
    }

    .content{
        flex-direction: column;
    }

    .content > div {
        text-align: center !important;
    }

    .content > div > div {
        display: flex;
        justify-content: center !important;
    }

    .content > img {
        margin: auto;
        margin-top: 70px;
        margin-bottom: 70px;
    }

}