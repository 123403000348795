.container{
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #16151A;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    overflow: hidden;
}

.container > img:first-child {
    position: absolute;
    width: 350px;
    bottom: -20px;
    right: 0;
}

.container > img:nth-child(2) {
    position: absolute;
    width: 200px;
    height: auto;
}


.container > div > p {
    margin: 0;
    color: white;
}

.container > div > h1 {
    margin: 0;
    color: white;
}


.container > div {
    width: 50%;
    margin: auto;
}

@media screen and (max-width: 950px){

    .container > img:first-child{
        display: none;
    }

    .container > div {
        position: relative;
        width: 80%;
        text-align: center;
    }

    .container > div > h1 {
        margin-top: 10px;
    }

}