.container{
    background-image: url("../../../public/background3.png");
    background-size:cover;
    padding: 50px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
}

.container > h1 {
    color: white;
    margin: 0;
    margin-bottom: 40px;
}

.steps{
    display: flex;
    justify-content: flex-start;
}

.step{
    position: relative;
    margin-left: 50px;
}

.step > p:first-child{
    background-color: #99C8CE;
    position: absolute;
    top: 5%;
    left: -15px;
    padding: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    text-align: center;
}

.step > div {
    width: 70px;
    height: 70px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center
}

.step > div > img {
    width: 30px;
    height: auto;
}

.step > p:last-child{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 950px){

    .container{
        padding: 0;
        padding-bottom: 30px;
        padding-top: 30px;
    }

    .container > h1{
        padding: 30px;
        padding-bottom: 0;
        text-align: center;
    }   

    .steps{
        overflow: scroll;
    }

    .step:last-child{
        margin-right: 50px;
    }

}