.box{
    background-color: #99C8CE;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
}

.box > p {
    background-color: #110A40;
    height: 100%;
    align-items: center;
    display: flex;
    margin: 0;
    padding: 7px;
    border-radius: 5px;
    color: white !important;
}

@media screen and (max-width: 950px){

    .box {
        width: 100%;
    }

}