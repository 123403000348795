@font-face {
    font-family: italiana;
    src: url("../../assets/fonts/italiana.ttf");
}

.container{
    background-color: #16151A;
    position: relative;
}

.titles{
    padding-top: 250px;
    padding-bottom: 250px;
    color: white;
}

.titles > h1 {
    font-family: italiana;
    text-align: center;
    margin: 0;
    font-size: 60px;
    font-weight: lighter;
}

.titles > p {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin: 0;
    margin-top: 10px;
    letter-spacing: 5px;
}

.laptop{
    position: absolute;
    width: 350px;
    height: auto;
    left: -10%;
    animation: 1.5s moveLaptop;
}

.mouse{
    position: absolute;
    width: 100px;
    height: auto;
    right: 30px;
    bottom: 100px;
    animation: 1.5s mouseMove;
}

.imac {
    position: absolute;
    width: 450px;
    height: auto;
    right: 0;
    top: 5px;
    animation: 1.5s imacLaptop;
}

@keyframes moveLaptop {

    0%{
        margin-left: -200px;
    }
    100%{
        margin: 0;
    }

}

@keyframes imacLaptop {

    0%{
        margin-top: -200px;
    }
    100%{
        margin: 0;
    }

}

@keyframes mouseMove {

    0%{
        margin-right: -200px;
    }
    100%{
        margin: 0;
    }

}



@media screen and (max-width: 950px){

    .imac{
        display: none;
    }

    .titles > *{
        position:relative;
    }

}