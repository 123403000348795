.container{
    padding-top: 100px;
    padding-bottom: 100px;
    font-family: Arial, Helvetica, sans-serif;
}

.content{
    width: 70%;
    margin: auto;
    margin-top: 100px;
}

.container > h1 {
    text-align: center;
    margin: 0;
    font-size: 40px;
}

.container > p {
    text-align: center;
    margin: 0;
    font-size: 20px;
    letter-spacing: 5px;
    margin-top: 10px;
    color: grey;
}

.container > div:nth-child(3) {
    background-color: #110A40;
     width: 50px;
     height: 6px;
     margin: auto;
     margin-top: 30px;
}

.skill{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.skill > div > h3{
    margin: 0;
    font-size: 15px;
    letter-spacing: 3px;
}

.skill > div > p {
    margin: 0;
    font-size: 12px;
    color: grey;
}

.skill > div:first-child {
    width: 50%;
}

.skill > div:last-child {
    width: 40%;
}


@media screen and (max-width: 950px){

    .container {
        padding: 20px;
        padding-top: 50px;
        overflow: hidden;
        padding-bottom: 50px;
    }

    .content{
        margin-top: 60px;
        width: 100%;
    }

    .skill{
        flex-direction: column;
        width: 90%;
        margin: auto;
        margin-top: 40px;
        border: 2px solid #F1F1F1;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .skill > div:first-child {
        width: 100%;
        text-align: center;
    }

    .skill > div:last-child{
        width: 90%;
        box-sizing: border-box;
        margin: auto;
        margin-top: 20px;
    }

}