.container{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    width: 40%;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 15%;
}

.container > a {
    font-size: 11px;
    color: white;
    text-decoration: none;
}


@media screen and (max-width: 950px){

    .container{
        display: none;
    }

}